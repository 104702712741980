export default {
  advisorInformation: "advisorInformation",
  accountantFirmDetails: "accountantFirmDetails",
  users: "users",
  businesses: "businesses",
  business: "business",
  businessClients: "businessClients",
  accounts: "accounts",
  me: "me",
  taxProfile: "taxProfile",
  selfAssessment: "selfAssessment",
  selfAssessmentExport: "selfAssessmentExport",
  snfReport: "snfReport",
  transactions: "transactions",
  transaction: "transaction",
  categoriesWithTotals: "categoriesWithTotals",
  exportPeriods: "exportPeriods",
  clientInvites: "clientInvites",
  clients: "clients",
  invoices: "invoices",
  invoicesSummary: "invoicesSummary",
  invoiceNumber: "invoiceNumber",
  invoiceVatRates: "invoiceVatRates",
  invoiceProfile: "invoiceProfile",
  invoiceUnits: "invoiceUnits",
  invoicePaymentMethods: "invoicePaymentMethods",
  categoriesForSelect: "categoriesForSelect",
  vatRates: "vatRates",
  incomeStreams: "incomeStreams",
  incomeStreamTypes: "incomeStreamTypes",
  attachments: "attachments",
  attachmentScanningStatusPolling: "attachmentScanningStatusPolling",
  unassignedAttachments: "unassignedAttachments",
  connections: "connections",
  connectionsStatusPolling: "connectionsStatusPolling",
  itsaConnection: "itsaConnection",
  itsaBusinesses: "itsaBusinesses",
  itsaTaxCalculations: "itsaTaxCalculations",
  itsaObligationReport: "itsaObligationReport",
  goSimpletax: "goSimpletax",
  advisor: "advisor",
  advisorClients: "advisorClients",
  advisorInvitation: "advisorInvitation",
  accountantFirmList: "accountantFirmList",
};
