import React from "react";
import * as Sentry from "@sentry/react";
import ErrorFallbackPage from "src/screens/ErrorFallback";

const GlobalErrorBoundary: React.FC = ({ children }) => (
  <Sentry.ErrorBoundary fallback={ErrorFallbackPage}>
    {children}
  </Sentry.ErrorBoundary>
);

export default GlobalErrorBoundary;
