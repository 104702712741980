import React from "react";
import { Toast, ToastBar } from "react-hot-toast";
import styled from "styled-components";

const ToastContentContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

const ToastIconContainer = styled.div`
  margin: 6px 0 0 3px;
`;

type Props = {
  toastEntry: Toast;
};

// Toaster component
export const ToastItem: React.FC<Props> = ({ toastEntry }) => {
  return (
    <ToastBar toast={toastEntry}>
      {({ icon, message }) => (
        <ToastContentContainer>
          <ToastIconContainer>{icon}</ToastIconContainer>
          {message}
        </ToastContentContainer>
      )}
    </ToastBar>
  );
};
