import React from "react";
import { Toaster } from "react-hot-toast";
import { ToastItem } from "./ToastItem";

export const ToastHost: React.FC = () => {
  return (
    <Toaster
      containerStyle={{
        top: 136,
        left: 24,
        bottom: 24,
        right: 24,
      }}
      toastOptions={{
        style: {
          background: "var(--white)",
          color: "var(--husk)",
        },
        success: {
          iconTheme: {
            primary: "var(--seaweed)",
            secondary: "var(--white)",
          },
        },
        error: {
          iconTheme: {
            primary: "var(--darkWatermelon)",
            secondary: "var(--white)",
          },
        },
      }}
    >
      {(toast) => <ToastItem toastEntry={toast} />}
    </Toaster>
  );
};
