import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import axios from "axios";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: process.env.REACT_APP_SENTRY_RELEASE,
  integrations: [new Integrations.BrowserTracing()],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  beforeSend: function (event: Sentry.Event, hint?: Sentry.EventHint) {
    // Add axios response into extra for any API errors
    if (hint?.originalException && axios.isAxiosError(hint.originalException)) {
      event.extra = {
        ...event.extra,
        response: JSON.stringify(hint.originalException.response?.data),
      };
    }
    return event;
  },
});
