// https://developers.intercom.com/installing-intercom/docs/basic-javascript
const initialise = () => {
  // Initialises Intercom code.
  // N.B. Doesn't actually show the intercom bubble - need to 'boot' to show it.
  // N.B. copied from https://www.intercom.com/help/en/articles/170-integrate-intercom-in-a-single-page-app
  var w = window;
  var ic = w.Intercom;
  if (typeof ic === "function") {
    ic("reattach_activator");
    ic("update", w.intercomSettings);
  } else {
    var d = document;
    var i = function () {
      i.c(arguments);
    };
    i.q = [];
    i.c = function (args) {
      i.q.push(args);
    };
    w.Intercom = i;
    var l = function () {
      var s = d.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.src =
        "https://widget.intercom.io/widget/" +
        process.env.REACT_APP_INTERCOM_APP_ID;
      var x = d.getElementsByTagName("script")[0];
      x.parentNode.insertBefore(s, x);
    };
    if (w.attachEvent) {
      w.attachEvent("onload", l);
    } else {
      w.addEventListener("load", l, false);
    }
  }
};

// 'boot'-ing causes the intercom bubble to be displayed in the UI
const boot = () => {
  window.Intercom("boot", {
    app_id: process.env.REACT_APP_INTERCOM_APP_ID,
  });
};

// 'update' when new user data becomes available, e.g. when the user logs in
const update = (props) => {
  window.Intercom("update", {
    ...props,
    app_id: process.env.REACT_APP_INTERCOM_APP_ID,
  });
};

const trackEvent = (eventId, eventData = undefined) => {
  window.Intercom("trackEvent", eventId, eventData);
};

// 'shutdown' when the user logs out, to clear their user data from intercom. You need to
// immediately call 'boot' after that, or the intercom bubble will disappear.
const shutdown = () => window.Intercom("shutdown");

export default {
  initialise,
  boot,
  update,
  trackEvent,
  shutdown,
};
