import React, { createContext, useMemo, useCallback } from "react";
import { useHistory } from "react-router-dom";
import {
  UseMutateAsyncFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import * as Sentry from "@sentry/react";
import Loading from "src/components/Loading";
import { useQueryStringRedirect } from "src/utils/hooks";
import { Intercom, Mixpanel } from "src/utils/analytics";
import queryKeys from "src/api/queryKeys";
import { logout } from "src/api/session";

export type AuthState = {
  isAuthenticated: boolean;
  login: UseMutateAsyncFunction<unknown, unknown, any>;
  isLoginLoading: boolean;
  logout: UseMutateAsyncFunction;
  isLogoutLoading: boolean;
  cleanupAfterLogout: () => void;
};

type AuthProviderProps = {
  children: React.ReactNode;
};

type CreateAuthProviderProps<TAuthState, TCurrentUser> = {
  loginSuccessUrl: string;
  logoutSuccessUrl: string;
  loginFn: UseMutateAsyncFunction<unknown, unknown, any>;
  transformCurrentUserToContextValue: (arg: TCurrentUser) => TAuthState;
  getMeFn: () => Promise<TCurrentUser>;
};

const createAuthProvider = <TAuthState, MeData>({
  loginSuccessUrl,
  logoutSuccessUrl,
  loginFn,
  transformCurrentUserToContextValue,
  getMeFn,
}: CreateAuthProviderProps<TAuthState, MeData>) => {
  const AuthContext = createContext<(TAuthState & AuthState) | null>(null);

  const AuthProvider = ({ children }: AuthProviderProps) => {
    const history = useHistory();
    const queryClient = useQueryClient();
    const redirect = useQueryStringRedirect();

    // enabled = false means that the query does not run automatically.
    // Instead the query is controlled using `refetch`. This is done
    // because there is an axios interceptor which de-authenticates
    // the user on HTTP 403. See the comment in `logout` method below
    // for further details.
    const { status, data } = useQuery(queryKeys.me, getMeFn, {
      retry: false,
    });

    const login = useMutation(loginFn, {
      onSuccess: async () => {
        await queryClient.invalidateQueries([queryKeys.me]);
        redirect(loginSuccessUrl);
      },
    });

    const executeLogout = useMutation(logout, {
      onSuccess: async () => {
        await cleanupAfterLogout();
      },
      onError: (error) => {
        console.log(error);
      },
    });

    const cleanupAfterLogout = useCallback(async () => {
      // Common logic which needs to run when the user is
      // logged out - either after logging out via the api,
      // or being force-logged out by the app (eg getting a
      // permission denied response from the api)

      queryClient.clear();

      history.push(logoutSuccessUrl);
      Intercom.shutdown();

      // immediately boot up intercom again, so the bubble doesn't disappear
      Intercom.boot();

      Mixpanel.reset();

      Sentry.configureScope((scope) => scope.setUser(null));
    }, [history, queryClient]);

    const value = useMemo(() => {
      const userData =
        data && transformCurrentUserToContextValue(data as MeData);
      return {
        ...(userData as TAuthState),
        isAuthenticated: !!data,
        login: login.mutateAsync,
        isLoginLoading: login.isLoading,
        logout: executeLogout.mutateAsync,
        isLogoutLoading: executeLogout.isLoading,
        cleanupAfterLogout,
      };
    }, [login, executeLogout, data, cleanupAfterLogout]);

    if (status === "idle") {
      return <Loading />;
    }

    if (status === "error") {
      // An error means that the user is un-authenticated (HTTP 403 permission denied)
      return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
      );
    }

    if (status === "loading") {
      return <Loading />;
    }

    if (status === "success") {
      // The user is authenticated.
      // The `currentUser` might not yet be set in which case show the loading spinner.
      // As soon as the `currentUser` has been set, its safe to render the rest of the
      // app.
      return data ? (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
      ) : (
        <Loading />
      );
    }

    throw new Error(`AuthProvider has invalid status: ${status}`);
  };

  const useAuth = () => {
    const context = React.useContext(AuthContext);

    if (context === undefined) {
      throw new Error("`useAuth` must be used within a `AuthProvider`");
    }

    return context;
  };

  return { useAuth, AuthProvider };
};

export { createAuthProvider };
