import { useRef, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { usePortalVariant } from "src/hooks/usePortalVariant";
import { PortalVariant } from "src/config";

export const usePrevious = <T>(value: T) => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useDidChange = (deps: unknown[]) => {
  const depsRef = useRef(deps);

  let changed = false;

  if (
    depsRef.current.length !== deps.length ||
    deps.some((dep, index) => depsRef.current[index] !== dep)
  ) {
    changed = true;
  }

  useEffect(() => {
    depsRef.current = deps;
  }, [deps]);

  return changed;
};

const getRootTitle = (variant: PortalVariant) =>
  variant === "accountant" ? "Coconut Accountant Portal" : "Coconut Web";

export const useDocumentTitle = (nestedTitle = "") => {
  const variant = usePortalVariant();
  useEffect(() => {
    if (nestedTitle && nestedTitle.length > 0) {
      document.title = `${nestedTitle} | ${getRootTitle(variant)}`;
    } else {
      document.title = getRootTitle(variant);
    }
  }, [nestedTitle, variant]);
};

export const useQueryStringRedirect = () => {
  const location = useLocation();
  const history = useHistory();

  const params = new URLSearchParams(location.search);
  const redirectTo = params.get("redirect");

  const redirect = (defaultPath: string) => {
    const newPath = redirectTo || defaultPath;

    history.push(newPath);
  };

  return redirect;
};
