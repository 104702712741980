// N.B. polyfills must come before any other imports
// N.B. during the build process, babel only includes the specific polyfills
// required for the browsers listed in package.json's browserslist (✅ I (JackLJ)
// manually checked this was working correctly in Feb 2021)
import "react-app-polyfill/stable"; // for Legacy Edge (Edge18) support - specifically for Array.prototype.flat()

import React from "react";
import ReactDOM from "react-dom";
import "src/utils/sentry";
import { QueryClient } from "react-query";
import AccountantsApp from "src/apps/accountant/AccountantApp";
import { variant, reactQueryConfig } from "src/config";
import CustomerApp from "src/apps/customer/CustomerApp";

const App = variant === "accountant" ? AccountantsApp : CustomerApp;

ReactDOM.render(
  <App queryClient={new QueryClient(reactQueryConfig)} />,
  document.getElementById("root")
);
