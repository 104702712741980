import React, { Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClient, QueryClientProvider } from "react-query";
import { Intercom, Mixpanel } from "src/utils/analytics";
import Loading from "src/components/Loading";
import GlobalErrorBoundary from "src/utils/sentry/GlobalErrorBoundary";
import reactLazyWithRetries from "src/utils/reactLazyWithRetries";
import "src/scss/style.scss";
import { useDocumentTitle } from "src/utils/hooks";
import ProtectedRoute from "src/components/ProtectedRoute";
import InjectAxiosInterceptors from "src/components/InjectAxiosInterceptors";
import { PortalVariantProvider } from "src/hooks/usePortalVariant";
import { ToastHost } from "src/components/Toast/ToastHost";
import { ViewportInfoProvider } from "src/hooks/useViewportInfo";
import { useAuth, AuthProvider, AccountantAuthState } from "./AuthProvider";

Intercom.initialise();
Intercom.boot();

Mixpanel.initialise();

// Screens
// N.B. the `/* webpackChunkName: "xyz" */` comments specify the filename prefix of the
// JS chunk files that webpack generates.
const LoggedIn = reactLazyWithRetries(
  () =>
    import(
      /* webpackChunkName: "LoggedIn" */ "src/apps/accountant/screens/LoggedIn"
    )
);
const Login = reactLazyWithRetries(
  () =>
    import(/* webpackChunkName: "Login" */ "src/apps/accountant/screens/Login")
);
const ForgotPassword = reactLazyWithRetries(
  () =>
    import(
      /* webpackChunkName: "ForgotPassword" */ "src/apps/accountant/screens/ForgotPassword"
    )
);
const ResetPassword = reactLazyWithRetries(
  () =>
    import(
      /* webpackChunkName: "ResetPassword" */ "src/apps/accountant/screens/ResetPassword"
    )
);
const SignUp = reactLazyWithRetries(
  () =>
    import(
      /* webpackChunkName: "SignUp" */ "src/apps/accountant/screens/SignUp"
    )
);

// we have a route for this just so product can look at it easily
const ErrorFallback = reactLazyWithRetries(
  () =>
    import(/* webpackChunkName: "ErrorFallback" */ "src/screens/ErrorFallback")
);

const Routes = () => {
  const { isAuthenticated } = useAuth() as AccountantAuthState;

  return (
    <Switch>
      <Route exact path="/500" component={ErrorFallback} />
      <ProtectedRoute
        isAllowed={!isAuthenticated}
        redirectToIfNotAllowed="/clients"
        exact
        path="/sign-up"
        component={SignUp}
      />
      <ProtectedRoute
        isAllowed={!isAuthenticated}
        exact
        redirectToIfNotAllowed="/clients"
        includeRedirectQueryParam={true}
        path="/login"
        component={Login}
      />
      <ProtectedRoute
        isAllowed={!isAuthenticated}
        exact
        redirectToIfNotAllowed="/clients"
        path="/forgot"
        component={ForgotPassword}
      />
      <ProtectedRoute
        isAllowed={!isAuthenticated}
        redirectToIfNotAllowed="/clients"
        exact
        path="/reset"
        component={ResetPassword}
      />
      <ProtectedRoute
        isAllowed={isAuthenticated}
        redirectToIfNotAllowed="/login"
        path="/"
        component={LoggedIn}
        includeRedirectQueryParam={true}
      />
    </Switch>
  );
};

export type AccountantAppProps = {
  queryClient: QueryClient;
};

const AccountantApp: React.FC<AccountantAppProps> = ({
  queryClient,
}: {
  queryClient: QueryClient;
}) => {
  useDocumentTitle();

  return (
    <ViewportInfoProvider>
      <PortalVariantProvider variant="accountant">
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <GlobalErrorBoundary>
              <AuthProvider>
                <InjectAxiosInterceptors useAuthHook={useAuth} />
                <Suspense fallback={<Loading />}>
                  <Routes />
                </Suspense>
                <ToastHost />
              </AuthProvider>
            </GlobalErrorBoundary>
          </BrowserRouter>
          {process.env.NODE_ENV === "development" && <ReactQueryDevtools />}
        </QueryClientProvider>
      </PortalVariantProvider>
    </ViewportInfoProvider>
  );
};

export default AccountantApp;
