const events = {
  // Signup
  signup_view: "signup_view",
  signup_submit: "signup_submit",

  // Login
  login_credentials_submit: "login_credentials_submit",
  login_two_factor_challenge_submit: "login_two_factor_challenge_submit",
  login_resend_code: "login_resend_code",
  login_view: "login_view",
  forgotpw: "forgotpw",

  // Clients
  clients_view: "clients_view",
  clients_showperpage: "clients_showperpage",
  clients_search: "client_search",

  // Bank feeds
  bank_feeds_view: "bank_feeds_view",
  bank_feeds_export_account: "bank_feeds_export_account",
  manual_tx_create: "manual_tx_create",
  manual_tx_delete: "manual_tx_delete",
  bank_feeds_showperpage: "bank_feeds_showperpage",
  bank_feeds_search: "bank_feeds_search",

  // Bookkeeping
  bookkeeping_view: "bookkeeping_view",
  bookkeeping_export_all: "bookkeeping_export_all",
  bookkeeping_search: "bookkeeping_search",
  bookkeeping_show_all_categories: "bookkeeping_show_all_categories",
  tx_review: "tx_review",
  tx_review_all: "tx_review_all",
  tx_edit: "tx_edit",
  tx_recategorise: "tx_recategorise",
  tx_edit_tax: "tx_edit_tax",
  tx_edit_notes: "tx_edit_notes",
  tx_sort: "tx_sort",
  tx_showperpage: "tx_showperpage",
  tx_add_cis_deduction: "tx_add_cis_deduction",
  tx_update_cis_deduction: "tx_update_cis_deduction",
  tx_delete_cis_deduction: "tx_delete_cis_deduction",
  tx_edit_split_transaction: "tx_edit_split_transaction",
  tx_add_split_transaction: "tx_add_split_transaction",
  tx_remove_split_transaction: "tx_remove_split_transaction",

  // Query Transactions
  querytx_select: "querytx_select",
  querytx_copy: "querytx_copy",

  // Reports
  reports_view: "reports_view",
  reports_export_category: "reports_export_category",
  reports_export_snf: "reports_export_snf",
  reports_export_SA10X: "reports_export_SA10X",
  reports_view_SA10X: "reports_view_SA10X",
  reports_export_profitloss: "reports_export_profitloss",
  reports_view_profitloss: "reports_view_profitloss",

  // Invoices
  invoices_view: "invoices_view",
  invoices_export: "invoices_export",
  invoices_showperpage: "invoices_showperpage",
  invoices_search: "invoices_search",
  invoice_create: "invoice_create",
  invoice_close: "invoice_close",
  invoice_send: "invoice_send",
  invoice_delete: "invoice_delete",
  invoice_mark_paid: "invoice_mark_paid",
  invoice_duplicate: "invoice_duplicate",
  invoice_resend: "invoice_resend",

  // Attachments
  click_add_attachment: "click_add_attachment",
  add_attachment_success: "add_attachment_success",
  view_attachment: "view_attachment",
  click_upload_attachment_from_computer:
    "click_upload_attachment_from_computer",
  click_attach_saved_receipt: "click_attach_saved_receipt",
  unassign_attachment_success: "unassign_attachment_success",

  // Tax
  tax_income_modal: "tax_income_modal",
  tax_income_save: "tax_income_save",
  tax_income_discard: "tax_income_discard",

  // Misc
  profile_demo: "profile_demo",

  // Client invites
  click_add_a_client_button: "click_add_a_client_button",
  click_add_client_button: "click_add_client_button",
  click_add_and_send_invite_button: "click_add_and_send_invite_button",
  click_send_invite_from_invitations_table:
    "click_send_invite_from_invitations_table",
  click_resend_invite_from_invitations_table:
    "click_resend_invite_from_invitations_table",
  click_resend_request_from_invitations_table:
    "click_resend_request_from_invitations_table",
  click_next_after_entering_new_client_email:
    "click_next_after_entering_new_client_email",
  close_add_new_client_modal: "close_add_new_client_modal",
  close_invite_existing_client_modal: "close_invite_existing_client_modal",
  delete_client_invite: "delete_client_invite",

  // Discovery
  discovery_mtditsa: "discovery_mtditsa",

  // Contacts
  contacts_view: "contacts_view",
  contact_edit: "contact_edit",
  contact_create: "contact_create",
  contact_save: "contact_save",

  // Details
  details_view: "details_view",

  // Income Streams
  income_stream_create: "income_stream_create",
  income_stream_edit: "income_stream_edit",
  income_stream_save: "income_stream_save",
  income_stream_disable: "income_stream_disable",
  income_stream_enable: "income_stream_enable",
  income_stream_primary: "income_stream_primary",
  income_stream_filter: "income_stream_filter",

  // Receipts
  receipts_view: "receipts_view",
  receipts_filter_linked: "receipts_filter_linked",
  receipts_filter_unlinked: "receipts_filter_unlinked",
  receipt_add: "receipt_add",
  receipt_add_bulk: "receipt_add_bulk",
  receipt_drop: "receipt_drop",
  receipt_edit: "receipt_edit",
  receipt_view_download: "receipt_view_download",
  receipt_delete: "receipt_delete",
  receipt_tx_edit: "receipt_tx_edit",
  receipt_tx_create: "receipt_tx_create",
  receipt_tx_link: "receipt_tx_link",
  receipt_tx_link_suggested: "receipt_tx_link_suggested",
  receipt_tx_unlink: "receipt_tx_unlink",
};

const properties = {
  "Number of transactions": "Number of transactions",
  "Edit actions": "Edit actions",
  "Is manual adjustment": "Is manual adjustment",
  "Payment direction": "Payment direction",
  Action: "Action",
};

export default {
  events,
  properties,
};
