import React from "react";
import styled from "styled-components";

type LoadingVariant = "default" | "subtle";

const Container = styled.div<{ $variant: LoadingVariant }>`
  ${(props) => props.$variant === "subtle" && `color: var(--rock);`}
`;

type LoadingProps = {
  className?: string;
  variant?: LoadingVariant;
  children?: React.ReactNode;
};

const Loading = ({
  className,
  variant = "default",
  children,
}: LoadingProps) => (
  <Container
    className={`animated fadeIn text-center mt-2 ${className && className} `}
    $variant={variant}
  >
    {children || "Loading..."}
  </Container>
);

export default Loading;
