import React from "react";
import styled from "styled-components";

import coconutIcon from "src/assets/images/coconutIcon.svg";

const Page = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 20px;
`;

const CoconutIcon = styled.img`
  width: 70px;
  height: 70px;
  margin-right: 20px;
`;

const Details = styled.div``;

const H4 = styled.h4`
  font-size: 1.3125rem;
  margin-bottom: 0.3rem;
`;

const Muted = styled.p`
  color: var(--rock);
  margin-bottom: 0;
  padding-bottom: 0;
`;

const ErrorFallback = () => (
  <Page>
    <Container>
      <CoconutIcon src={coconutIcon} alt="Coconut" />
      <Details>
        <H4>Please reload the page</H4>
        <Muted>
          An error has occurred. Don&apos;t worry, we&apos;ve notified the
          Coconut team, but get in touch with our{" "}
          <a href="mailto:support@getcoconut.com">customer service</a> if the
          problem persists.
        </Muted>
      </Details>
    </Container>
  </Page>
);

export default ErrorFallback;
