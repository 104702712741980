import React from "react";

const RETRY_DELAY = 1500;
const DEFAULT_MAX_NUMBER_OF_RETRIES = 3;

// https://medium.com/@botfather/react-loading-chunk-failed-error-88d0bb75b406
// This function calls the passed in thenable function, and retries it if the Promise
// it returns rejects.
function retryThenableFunction(
  dynamicImportFunc: () => Promise<{ default: React.ComponentType }>,
  attemptsLeft: number
): Promise<{ default: React.ComponentType }> {
  return new Promise((resolve, reject) => {
    dynamicImportFunc()
      .then(resolve)
      .catch((error) => {
        // retry after `RETRY_DELAY` ms
        setTimeout(() => {
          if (attemptsLeft === 1) {
            reject(error);
            return;
          }
          retryThenableFunction(dynamicImportFunc, attemptsLeft - 1).then(
            resolve,
            reject
          );
        }, RETRY_DELAY);
      });
  });
}

// React.lazy() lets you render a dynamically imported component as if it were a regular
// component - it returns a special component which, when it is first rendered, loads
// the bundle containing your lazy-loaded component.
// React.lazy() takes a function that must call a dynamic import(). This must return a Promise
// which resolves to a module with a default export containing a React component.
//
// The dynamic `import()` is function-like (it's not actually a function), and returns a Promise
// that resolves to the module if it successfully loaded, and rejects otherwise (for example,
// due to network issues).
//
// We can use this behaviour to retry the dynamic import if it fails: we retry it 3 times, and if
// it still fails, then just reject the top level Promise so that an error can be thrown.
export default function reactLazyWithRetries(
  dynamicImportFunc: () => Promise<{ default: React.ComponentType }>,
  numberOfRetries = DEFAULT_MAX_NUMBER_OF_RETRIES
) {
  return React.lazy(() =>
    retryThenableFunction(dynamicImportFunc, numberOfRetries)
  );
}
